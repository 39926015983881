<template>
  <div v-if="content != null">
    <PagesPath :pathTitle1="'تماس با ما'" :pathTo1="'contact'"></PagesPath>
    <section
      class="
          contact
          container
          my-sm-5 my-4
          box-shaddow20
          bg-white

          p-3
        "
    >
      <div class="row">
        <div class="col-lg-12 col-sm-12 col-12 column">
          <div
            class="ge-content ge-content-type-ckeditor"
            data-ge-content-type="ckeditor"
          >
            <h1>
              <span style="color:#000000">تماس با آنجل بار</span>
            </h1>

            <p style="text-align:justify">
              <span style="color:#000000"
                ><span style="font-size:16px"
                  >خوشحال می‌شویم نظرات، پیشنهادات و سوالات شما را بشنویم! تیم
                  ما آماده پاسخگویی به شماست.</span
                ></span
              >
            </p>

            <p>&nbsp;</p>

            <p>
              <span style="color:#000000"
                ><strong
                  ><span style="font-size:18px">تلفن همراه:</span></strong
                ></span
              >
            </p>

            <p>
              <span style="font-size:16px"
                ><span style="color:#000000"
                  >ساعات پاسخگویی 9 تا 14 و 17 تا 21&nbsp;</span
                ></span
              >&nbsp;
            </p>

            <p>
              <span style="color:#000000"
                ><span style="font-size:16px">پیگیری سفارش‌ها:</span></span
              >&nbsp;
              <span style="font-size:16px"
                ><a href="tel:09030026535">09030026535</a></span
              >
            </p>

            <p>
              <span style="font-size:16px"
                ><span style="color:#000000">مشاوره و خرید:</span></span
              >&nbsp;&nbsp;&nbsp;<span style="font-size:16px"
                ><a href="tel:09058059560">09058059560</a></span
              >
            </p>

            <p>
              <strong
                ><span style="color:#000000"
                  ><span style="font-size:18px">شبکه‌های اجتماعی:</span></span
                ></strong
              >
            </p>

            <p>
              <span style="font-size:16px"
                ><span style="color:#000000">مشاوره تخصصی و رایگان</span></span
              >
            </p>

            <p>
              <a
                href="https://www.instagram.com/angel.beauty.bar/"
                target="_blank"
                ><img
                  alt="اینستاگرام"
                  src="https://upload.wikimedia.org/wikipedia/commons/e/e7/Instagram_logo_2016.svg"
                  style="height:35px; width:35px"
                />&nbsp;</a
              >&nbsp; &nbsp;
              <span style="color:#000000"
                ><span style="font-size:16px"
                  >اینستاگرام angel.beauty.bar</span
                ></span
              >
            </p>

            <p>
              <a
                href="https://api.whatsapp.com/send?phone=989116110005&amp;text=%D8%B3%D9%84%D8%A7%D9%85%D8%8C%20%D8%A7%D8%B2%20%D8%B3%D8%A7%DB%8C%D8%AA%20%D8%A7%D9%86%D8%AC%D9%84%20%D8%A8%D8%A7%D8%B1%20%D9%BE%DB%8C%D8%A7%D9%85%20%D9%85%DB%8C%D8%AF%D9%85"
                target="_blank"
                ><img
                  alt="واتساپ"
                  src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
                  style="height:40px; width:40px"/></a
              >&nbsp; &nbsp;
              <span style="font-size:16px"
                ><span style="color:#000000">واتساپ&nbsp; &nbsp;</span></span
              >
            </p>
          </div>
        </div>
      </div>

      <hr />
      <p >
        <span style="font-size:16px"
          ><span style="color:#000000"
            >شما عزیزان می‌توانید از طریق فرم زیر پیام خود را بگذارید، کارشناسان
            ما بعد از بررسی با شما تماس خواهند گرفت.</span
          ></span
        >
      </p>
      <form>
        <div class="contact-grid fontsize14">
          <input
            class="bg-color-eai   border p-3"
            ref="name"
            type="text"
            placeholder="نام:"
          />
          <input
            class="bg-color-eai   border p-3"
            ref="phone"
            :type="mediaQueries.mobileSize == true ? 'tel' : 'number'"
            placeholder="شماره تماس:"
          />
          <input
            class="bg-color-eai   border p-3"
            ref="subject"
            type="text"
            placeholder="موضوع:"
          />
        </div>
        <textarea
          class="bg-color-eai   border p-3 w-100 mt-3"
          ref="body"
          id=""
          cols="30"
          rows="10"
          placeholder="پیام:"
        ></textarea>
        <div
          id="contactCheckAndSubmit"
          class="
              d-flex
              justify-content-between
              align-items-baseline
              mt-3
              fontsize14
            "
        >
          <div v-if="question != null" class="contact-text">
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.9999 2.75021C17.1089 2.75021 21.2499 6.89221 21.2499 12.0002C21.2499 17.1082 17.1089 21.2502 11.9999 21.2502C6.89188 21.2502 2.74988 17.1082 2.74988 12.0002C2.74988 6.89221 6.89188 2.75021 11.9999 2.75021Z"
                stroke="#999"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.995 8.20432V12.6233"
                stroke="#999"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.995 15.7961H12.005"
                stroke="#999"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span class="fontsize14 text-color-999 mr-1">
              {{ question }}
            </span>
            <input
              id="answerBox"
              ref="answer"
              class="bg-color-eai   border p-3"
              type="text"
            />
          </div>
          <input
            class="w-25 bg-color-theme   p-3 text-white"
            :class="{ disabled: disabled }"
            @click.prevent="sendForm"
            type="submit"
            value="ارسال پیام"
          />
        </div>
      </form>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import PagesPath from "@/parts/Front/components/PagesPath";
import ZTwoArrowSvg from "../components/shared/ZTwoArrowSvg";
import SocialNetworks from "../components/shared/SocialNetworks";
import config from "../../../config";
export default {
  name: "Contact",
  inject: ["mediaQueries"],
  components: {
    SocialNetworks,
    ZTwoArrowSvg,
    PagesPath,
  },
  data() {
    return {
      code: null,
      question: null,
      disabled: false,
    };
  },
  computed: {
    // دریافت محتوای درخواست هوم
    content() {
      return this.$store.getters["front/getHomeData"];
    },
    loading() {
      return this.$store.getters["front/getHomeDataStatus"];
    },
  },
  mounted() {
    this.$axios.get("all/contacts/create").then((response) => {
      this.code = response.data.data.name;
      this.question = response.data.data.message;
    });
  },
  methods: {
    sendForm() {
      if (this.$refs.name.value.length < 2) {
        this.$refs.name.classList.add("inputError");
        return window
          .swal({
            title: "وارد کردن  نام الزامی است",
            text: "نام باید شامل دو کارکتر یا بیشتر باشد",
            icon: "warning",

            showConfirmButton: true,
            dangerMode: true,
            button: "متوجه شدم",
          })
          .then(() => {
            this.$refs.name.focus();
          });
      } else {
        this.$refs.name.classList.remove("inputError");
      }
      if (this.$refs.phone.value.length != 11) {
        this.$refs.phone.classList.add("inputError");
        return window
          .swal({
            title: "ورود کردن شماره تماس الزامی است",
            text: "شماره تماس شامل 11 رقم است",
            icon: "warning",

            showConfirmButton: true,
            dangerMode: true,
            button: "متوجه شدم",
          })
          .then(() => {
            this.$refs.phone.focus();
          });
      } else {
        this.$refs.phone.classList.remove("inputError");
      }
      if (this.$refs.subject.value.length < 3) {
        this.$refs.subject.classList.add("inputError");
        return window
          .swal({
            title: "موضوع ارتباط را به درستی وارد کنید",
            icon: "warning",

            showConfirmButton: true,
            dangerMode: true,
            button: "متوجه شدم",
          })
          .then(() => {
            this.$refs.subject.focus();
          });
      } else {
        this.$refs.subject.classList.remove("inputError");
      }
      if (this.$refs.body.value.length < 3) {
        this.$refs.body.classList.add("inputError");
        return window
          .swal({
            title: "متن پیام خود را وارد کنید",
            text: "این متن نباید از 10 کارکتر کمتر باشد",
            icon: "warning",

            showConfirmButton: true,
            dangerMode: true,
            button: "متوجه شدم",
          })
          .then(() => {
            this.$refs.body.focus();
          });
      } else {
        this.$refs.body.classList.remove("inputError");
      }
      if (this.$refs.answer.value.length < 1) {
        this.$refs.answer.classList.add("inputError");
        return window
          .swal({
            title: "پاسخ سوال امنیتی را وارد کنید",
            icon: "warning",

            showConfirmButton: true,
            dangerMode: true,
            button: "متوجه شدم",
          })
          .then(() => {
            this.$refs.answer.focus();
          });
      } else {
        this.$refs.answer.classList.remove("inputError");
      }
      const formData = new FormData();
      formData.append("name", this.$refs.name.value);
      formData.append("phone_number", this.$refs.phone.value);
      formData.append("subject", this.$refs.subject.value);
      formData.append("body", this.$refs.body.value);
      formData.append("" + this.code, this.$refs.answer.value);

      this.disabled = true;
      this.$axios
        .post("/all/contacts", formData)
        .then((res) => {
          this.disabled = false;
          this.$refs.name.value = null;
          this.$refs.phone.value = null;
          this.$refs.subject.value = null;
          this.$refs.body.value = null;
          this.$refs.answer.value = null;
          window.swal({
            title: "ارتباط با آنجل برقرار شد",
            text:
              "پیام شما در سیستم ما درج و به زودی پاسخ آنرا دریافت خواهید کرد باتشکر!",
            icon: "success",

            showConfirmButton: true,
            button: "متوجه شدم",
          });
        })
        .catch((err) => {
          this.disabled = false;
          window
            .swal({
              title: "پاسخ سوال امنیتی را  به درستی وارد نکرده اید",
              icon: "warning",

              showConfirmButton: true,
              dangerMode: true,
              button: "متوجه شدم",
            })
            .then(() => {
              this.$refs.answer.focus();
            });
        });
    },
  },
  metaInfo() {
    return {
      title: this.content
        ? config.title +
          ` - ${this.content.settings.general.meta_title_product_list}`
        : config.title,
      meta: [
        {
          name: "title",
          content: this.content
            ? this.content.settings.general.meta_title_product_list
            : false,
        },
        {
          name: "description",
          content: this.content
            ? this.content.settings.general.meta_description_product_list
            : false,
        },
        {
          property: "og:title",
          content: this.content
            ? this.content.settings.general.meta_title_product_list
            : false,
        },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
};
</script>

<style scoped>
/* برداشتن دکمه های بالا پایین برای ورود عدد */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
#answerBox {
  padding: 5px !important;
  width: 70px;
  border-radius: 4px;
}
#contactCheckAndSubmit {
  display: flex;
  flex-wrap: wrap;
}
.contact-text {
  margin-bottom: 10px;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.inputError {
  border: 1px solid red !important;
  background: #efe1e4;
}
</style>
