<template>
  <div>
    <main class="main product-details-main">
      <PagesPath path-title1="لیست محصولات" :pathTo1="$route.path" />
      <div class="product-list-main-grid container text-right mt-md-5 mt-3">
        <div class="welog-list-side d-lg-block d-none">
          <section class="products-list-filter box-shaddow20 p-3 bg-white mb-3">
            <div class="d-flex justify-content-center border-bottom pb-2">
              <ZFilterSvg />
              <h6 class="mr-2 fontsize-medium weight-bold text-color-444">
                فیلتر جستجو
              </h6>
            </div>
            <h6 class="mr-2 fontsize-medium weight-bold text-color-444 mt-3">
              دسته بندی ها
            </h6>
            <Categories
            v-if="homeData"
              :categories="homeData.categories"
              :categoryId="categoryId"
              :query="niceQuery"
            />
          </section>

          <ProductsFilter :loading="loading" v-model="query" />
        </div>
        <div class="weblog-list-main">
          <section
            class="filter-buttons d-flex flex-wrap justify-content-between mb-1 d-lg-none"
          >
            <button
              class="d-flex justify-content-center bg-color-ea py-3 px-3 mb-2 w-49"
              data-toggle="modal"
              data-target="#productFilterModal"
            >
              <svg
                class=""
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.12819 2H19.8718C21.0476 2 22 2.98105 22 4.19225V5.72376C22 6.31133 21.7704 6.87557 21.3627 7.28708L14.8577 13.867C14.7454 13.9816 14.5931 14.0452 14.4355 14.0441L8.98893 14.0272C8.82317 14.0272 8.66564 13.9561 8.55238 13.832L2.57452 7.25738C2.20489 6.85117 2 6.31451 2 5.7577V4.19332C2 2.98211 2.95238 2 4.12819 2ZM9.2801 15.8241L14.1347 15.839C14.4374 15.8401 14.6824 16.0935 14.6824 16.4043V19.1353C14.6824 19.4471 14.5053 19.7293 14.2294 19.8597L9.8227 21.9289C9.71974 21.9767 9.61061 22 9.50147 22C9.35629 22 9.21112 21.9576 9.08448 21.8738C8.86311 21.7274 8.72927 21.475 8.72927 21.2046V16.3894C8.72927 16.0766 8.97637 15.8231 9.2801 15.8241Z"
                  fill="#444"
                />
              </svg>
              <span class="mr-2 weight-bold fontsize15 text-color-444"
                >فیلتر جستجو</span
              >
            </button>
            <button
              class="d-flex justify-content-center bg-color-ea py-3 px-sm-3 px-2 mb-2 w-49"
              data-toggle="modal"
              data-target="#productOrderModal"
            >
              <svg
                class=""
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.1172 17.9866H2.88306"
                  stroke="#444"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M21.1179 17.9866C21.1179 19.5772 19.8285 20.8666 18.2379 20.8666C16.6473 20.8666 15.3579 19.5772 15.3579 17.9866C15.3579 16.3948 16.6473 15.1066 18.2379 15.1066C19.8285 15.1066 21.1179 16.3948 21.1179 17.9866Z"
                  stroke="#444"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.8827 6.26212H21.118"
                  stroke="#444"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.8826 6.26211C2.8826 7.85388 4.17201 9.14211 5.7626 9.14211C7.35319 9.14211 8.6426 7.85388 8.6426 6.26211C8.6426 4.67152 7.35319 3.38211 5.7626 3.38211C4.17201 3.38211 2.8826 4.67152 2.8826 6.26211Z"
                  stroke="#444"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span class="mr-2 fontsize15 weight-bold text-color-444"
                >ترتیب نمایش</span
              >
            </button>
          </section>
          <section
            @click="openAllCategory"
            v-if="mediaQueries.mobileSize && $route.params.slug"
            class="mobile-category"
          >
            <h1 class="fontsize-22 mb-0">
              {{ categoryContent.title }}
            </h1>
          </section>

          <ProductsFilterMobile v-model="query" />
          <ProductsSortMobile v-model="query" />

          <section
            class="product-view-order d-lg-flex d-none justify-content-between align-items-baseline flex-wrap bg-white box-shaddow20 p-3 mb-3"
          >
            <div class="order-0">
              <template v-if="!$route.params.slug">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-filter"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"
                    fill="#999"
                  />
                </svg>
                <span
                  class="text-color-666 fontsize-medium weight-bold"
                  v-if="!$route.params.slug"
                  >ترتیب نمایش:</span
                >
              </template>

              <h1 v-else class="weight-bold mr-2 mb-0 fontsize-md-18">
                {{ $route.params.slug.replaceAll("-", " ") }}
              </h1>
            </div>
            <ProductsSort v-model="query" :loading="loading" :filter="query" />
          </section>
          <section
            v-if="products.data != ''"
            class="products-list-grid recommended-content-item"
          >
            <transition name="fade">
              <div
                v-if="loading"
                :style="'top: ' + loadingPosition"
                class="loading-container"
              >
                <Loading />
              </div>
            </transition>
            <Product
              v-for="(product, index) in products.data"
              :low-opacity="loading"
              :key="index"
              :product="product"
              :comparable="true"
            />
          </section>
          <section
            v-else
            class="products-list-grid recommended-content-item product-empty bg-white box-shaddow20 py-4 px-5 mt-sm-0 mt-3"
          >
            <div class="position-relative w-100">
              <div
                v-if="loading"
                :style="'top: ' + loadingPosition"
                class="loading-container"
              >
                <Loading />
              </div>
              <div class="mr-2 fontsize14 weight-bold text-color-444 py-2">
                محصولی یافت نشد.
              </div>
              <router-link
                class="text-color-themeRed text-center d-block"
                to="/products"
                >مشاهده همه</router-link
              >
            </div>
          </section>
          <section
            v-if="products.data != ''"
            class="list-pagination weblog-list-pagination text-center my-4 py-2 fontsize-medium"
          >
            <pagination
              :router="true"
              :limit="mediaQueries.mobileSize ? 1 : 3"
              :data="products"
              @pagination-change-page="changePage"
              :disabled="disabled"
              class="justify-content-center"
            >
              <span slot="prev-nav">
                <svg
                  class="page-arrow mb-2"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="22"
                  height="22"
                  viewBox="0 0 226 226"
                  style="fill: #000000"
                >
                  <g
                    fill="none"
                    fill-rule="nonzero"
                    stroke="none"
                    stroke-width="1"
                    stroke-linecap="butt"
                    stroke-linejoin="miter"
                    stroke-miterlimit="10"
                    stroke-dasharray=""
                    stroke-dashoffset="0"
                    font-family="none"
                    font-weight="none"
                    font-size="none"
                    text-anchor="none"
                    style="mix-blend-mode: normal"
                  >
                    <path d="M0,226v-226h226v226z" fill="none"></path>
                    <g fill="#b2b2b2">
                      <path
                        d="M82.80781,169.67656c1.05938,1.05937 2.47187,1.58906 3.70781,1.58906c1.23594,0 2.64844,-0.52969 3.70781,-1.58906l52.96875,-52.96875c2.11875,-2.11875 2.11875,-5.47344 0,-7.41563l-52.96875,-52.96875c-2.11875,-2.11875 -5.47344,-2.11875 -7.41563,0c-2.11875,2.11875 -2.11875,5.47344 0,7.41563l49.26094,49.26094l-49.26094,49.26094c-2.11875,2.11875 -2.11875,5.29688 0,7.41562z"
                      ></path>
                    </g>
                  </g>
                </svg>
              </span>
              <span slot="next-nav">
                <svg
                  class="page-arrow"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="22"
                  height="22"
                  viewBox="0 0 226 226"
                  style="fill: #000000"
                >
                  <g
                    fill="none"
                    fill-rule="nonzero"
                    stroke="none"
                    stroke-width="1"
                    stroke-linecap="butt"
                    stroke-linejoin="miter"
                    stroke-miterlimit="10"
                    stroke-dasharray=""
                    stroke-dashoffset="0"
                    font-family="none"
                    font-weight="none"
                    font-size="none"
                    text-anchor="none"
                    style="mix-blend-mode: normal"
                  >
                    <path d="M0,226v-226h226v226z" fill="none"></path>
                    <g fill="#b2b2b2">
                      <path
                        d="M143.19219,56.32344c-2.11875,-2.11875 -5.47344,-2.11875 -7.41563,0l-52.96875,52.96875c-2.11875,2.11875 -2.11875,5.47344 0,7.41562l52.96875,52.96875c1.05937,1.05937 2.47188,1.58906 3.70781,1.58906c1.23594,0 2.64844,-0.52969 3.70781,-1.58906c2.11875,-2.11875 2.11875,-5.47344 0,-7.41563l-49.26094,-49.26094l49.26094,-49.26094c2.11875,-2.11875 2.11875,-5.29687 0,-7.41563z"
                      ></path>
                    </g>
                  </g>
                </svg>
              </span>
            </pagination>
          </section>
          <div
            v-html="categoryContent.description"
            v-if="categoryContent && categoryContent.description"
          />
        </div>
      </div>

      <ScrollToTop></ScrollToTop>
      <FooterButtons></FooterButtons>
    </main>
    <Footer></Footer>
  </div>
</template>

<script>
import ScrollToTop from "@/parts/Front/components/ScrollToTop";
import FooterButtons from "@/parts/Front/components/FooterButtons";
import Product from "@/parts/Front/components/product/Product";
import Categories from "@/parts/Front/components/categories/Categories";
import ProductsFilter from "@/parts/Front/components/filter/ProductsFilter";
import ProductsSort from "@/parts/Front/components/filter/ProductsSort";
import ProductsSortMobile from "@/parts/Front/components/filter/ProductsSortMobile";
import ProductsFilterMobile from "@/parts/Front/components/filter/ProductsFilterMobile";
import PagesPath from "@/parts/Front/components/PagesPath";
import Loading from "@/parts/Front/components/shared/Loading";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import ZFilterSvg from "@/parts/Front/components/shared/ZFilterSvg";
import pagination from "shetabit-laravel-vue-pagination";
import config from "../../../config";
export default {
  name: "ProductList",
  components: {
    Loading,
    PagesPath,
    ScrollToTop,
    FooterButtons,
    Product,
    Categories,
    ProductsFilter,
    ProductsSort,
    ProductsSortMobile,
    ProductsFilterMobile,
    ZFilterSvg,
    pagination,
  },
  inject: ["mediaQueries"],
  data() {
    return {
      categoryId: "",
      query: {
        sort: "",
        title: "",
        flash_id: "",
        max_price: 0,
        min_price: 0,
        available: 0,
        category_id: "",
        selected_sizes: Array(20).fill(false),
        color_ids: [],
        vip: 0,
        meta_title:""
      },
      loading: false,
      // چقدر از بالا به پایین اسکرول شده
      offsetFromTop: 0,
      // آخرین کویری درخواستی رو نگه میداریم که اگر فرق نکرد درخواست نزنیم
      lastQueryRequested: {},
    };
  },
  watch: {
    "$route.query.title"() {
      this.loadFiltersFromQuery();
    },
    "$route.query.category_id"() {
      this.meta_title = this.categoryContent.meta_title
      console.log( this.meta_title , "Product List")
      
    },
    query: {
      handler: function(newVal) {
        this.loadItems();
      },
      deep: true,
    },
  },
  created() {
    this.loadFiltersFromQuery();

    this.loadItems();
  },
  computed: {
    categoryContent() {
      if(this.$store.getters["front/getCategoryContent"] != null){
        this.meta_title = this.$store.getters["front/getCategoryContent"].meta_title
        console.log( this.meta_title , "Product List")
      }
      return this.$store.getters["front/getCategoryContent"];
    },
    disabled() {
      return this.$store.getters["front/getProductsStatus"];
    },
    homeData() {
      if (this.$store.getters["front/getHomeData"] != null) {
        return this.$store.getters["front/getHomeData"];
      } else {
        return null;
      }
    },
    products() {
      if (this.$store.getters["front/getProductsAll"] == null) {
        return {
          data: Array(6).fill(null),
        };
      } else {
        return this.$store.getters["front/getProductsAll"];
      }
    },
    loadingPosition() {
      return 160 + Math.min(1000, this.offsetFromTop) + "px";
    },
    niceQuery() {
      let selectedAttributeValueIds = this.getSelectedAttributeValueIds();
      let copyQuery = cloneDeep(this.query);
      copyQuery.sizes = selectedAttributeValueIds.join(",");
      delete copyQuery.selected_sizes;
      if (copyQuery.color_ids == "") {
        delete copyQuery.color_ids;
      }
      return copyQuery;
    },
  },
  mounted() {
    this.offsetFromTop = document.scrollingElement.scrollTop;
    document.removeEventListener("scroll", this.recalculateOffsetFromTop);
    document.addEventListener("scroll", this.recalculateOffsetFromTop);
   
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.recalculateOffsetFromTop);
  },
  methods: {
   
    openAllCategory() {
      document.querySelector(".show-category-button").click();
    },
    loadFiltersFromQuery() {
      this.query.page = this.$route.query.page || 1;
      this.categoryId = this.$route.params.category;
      this.query.color_ids = this.$route.query.color_ids
        ? this.$route.query.color_ids.map((id) => Number.parseInt(id))
        : this.query.color_ids;
      this.query.sort = this.$route.query.sort
        ? this.$route.query.sort
        : this.query.sort;
      this.query.title = this.$route.query.title
        ? this.$route.query.title
        : this.query.title;
      this.query.flash_id = this.$route.query.flash_id
        ? +this.$route.query.flash_id
        : this.query.flash_id;
      this.query.max_price = this.$route.query.max_price
        ? +this.$route.query.max_price
        : this.query.max_price;
      this.query.min_price = this.$route.query.min_price
        ? +this.$route.query.min_price
        : this.query.min_price;
      this.query.available = this.$route.query.available
        ? +this.$route.query.available
        : this.query.available;
      this.query.category_id = this.$route.params.category
        ? +this.$route.params.category
        : this.query.category_id;
      this.query.vip = this.$route.query.vip ? +this.$route.query.vip : 0;

      this.$store.commit("front/addHomeRetrievedEvent", (homeData) => {
        if (!this.$route.query.sizes) {
          return;
        }
        let sizesFromQuery = this.$route.query.sizes.split(",");
        let sizeValues = this.$store.getters["front/getSizeValues"].values;
        let newSelectedSizes = [...this.query.selected_sizes];
        sizeValues.forEach((sizeValue, index) => {
          if (sizesFromQuery.includes(sizeValue.id + "")) {
            newSelectedSizes[index] = true;
          }
        });
        this.query.selected_sizes = newSelectedSizes;
      });
    },
    recalculateOffsetFromTop() {
      this.offsetFromTop = document.scrollingElement.scrollTop;
    },
    changePage() {
      this.loadItems();
    },
    async loadItems() {
      // جلوگیری از 2 بار درخواست زدن
      if (this.loading) {
        return;
      }
      this.query.page = this.$route.query.page;
      // اگر فرقی تو کویری نیست درخواست نزن
      if (isEqual(this.lastQueryRequested, this.query)) {
        return;
      }

      this.loading = true;
      // برای فیلتر سایز اگر انتخاب شده بود
      let copyQuery = this.niceQuery;
      let selectedAttributeValueIds = this.getSelectedAttributeValueIds();
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.removeEmptyStrings(copyQuery),
          page: this.$route.query.page,
        },
      });

      this.lastQueryRequested = cloneDeep(this.query);
      await this.$store.dispatch("front/getProductsDataFromServer", {
        query: this.query,
        selectedAttributeValueIds,
      });
      this.loading = false;
    },
    getSelectedAttributeValueIds() {
      if (!this.$store.getters["front/getSizeValues"]) {
        if (!this.$route.query.sizes) {
          return [];
        }
        return this.$route.query.sizes.split(",");
      }
      let sizeValues = this.$store.getters["front/getSizeValues"].values;

      let selectedSizeValues = [];
      this.query.selected_sizes.forEach((SelectedSize, index) => {
        if (SelectedSize) {
          selectedSizeValues.push(sizeValues[index]);
        }
      });
      let attribute_value_ids = [];
      selectedSizeValues.forEach((selectedSizeValue) => {
        attribute_value_ids.push(selectedSizeValue.id);
      });
      return attribute_value_ids;
    },
    removeEmptyStrings(query) {
      let newQuery = {};
      for (let [index, value] of Object.entries(query)) {
        if (value != "") {
          newQuery[index] = value;
        }
      }

      return newQuery;
    },
  },
  metaInfo() {
    return {
      title: this.homeData
        ? config.title +
          ` - ${this.homeData.settings.general.meta_title_product_list}`
        : config.title,
      meta: [
        {
          name: "title",
          content: this.homeData
            ? this.homeData.settings.general.meta_title_product_list
            : false,
        },
        {
          name: "description",
          content: this.homeData
            ? this.homeData.settings.general.meta_description_product_list
            : false,
        },
        {
          property: "og:title",
          content: this.homeData
            ? this.homeData.settings.general.meta_title_product_list
            : false,
        },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
};
</script>

<style scoped>
.product-empty {
  grid-template-columns: 2fr !important;
}

.loading-container {
  position: absolute;
  top: 160px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  background: var(--color-theme2);
  border-radius: 10px;
  padding: 10px 0 1px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  transition: 200ms all;
}

.main >>> .vue-treeselect__multi-value-item {
  color: white;
  background: var(--color-theme);
}
.main >>> .vue-treeselect__value-remove {
  color: white;
}

h2 {
  font-size: 1.75rem;
}

.mobile-category {
  color: var(--color-444);
  background-color: var(--color-ea);
  font-size: 15px;
  transition: 0.3s;
  display: flex !important;
  justify-content: center !important;
  margin-bottom: 0.5rem !important;
  padding: 0.7rem !important;
}
.mobile-category h2 {
  margin-bottom: 0;
  font-weight: bold;
  font-size: 1.3rem;
}
.fontsize-22 {
  font-size: 22px;
}
</style>
