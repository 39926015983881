<template>
  <footer class="py-5 footer-bg">
    <section class="container">
      <div class="footer-grid">
        <div class="w-100">
          <img width="100" src="@/assets/images/logo.png" alt="" />
        </div>
        <div class="d-flex justify-content-between align-items-end">
          <h6 class="fontsize-medium font-weight-bold text-color-444 m-0">
            ما را در شبکه های اجتماعی دنبال کنید :
          </h6>
          <div
            class="d-flex align-items-end justify-content-start"
            style="gap: 15px;"
          >
            <a
              v-if="!loading && content.settings.social.whatsapp"
              :href="!loading ? content.settings.social.whatsapp : ''"
            >
              <svg
                width="20"
                height="20"
                fill="#000000"
                role="img"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>WhatsApp</title>
                <path
                  d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z"
                />
              </svg>
            </a>
            <a
              v-if="!loading && content.settings.social.telegram"
              :href="!loading ? content.settings.social.telegram : ''"
            >
              <svg
                width="20"
                height="20"
                fill="#000000"
                role="img"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Telegram</title>
                <path
                  d="M11.944 0A12 12 0 0 0 0 12a12 12 0 0 0 12 12 12 12 0 0 0 12-12A12 12 0 0 0 12 0a12 12 0 0 0-.056 0zm4.962 7.224c.1-.002.321.023.465.14a.506.506 0 0 1 .171.325c.016.093.036.306.02.472-.18 1.898-.962 6.502-1.36 8.627-.168.9-.499 1.201-.82 1.23-.696.065-1.225-.46-1.9-.902-1.056-.693-1.653-1.124-2.678-1.8-1.185-.78-.417-1.21.258-1.91.177-.184 3.247-2.977 3.307-3.23.007-.032.014-.15-.056-.212s-.174-.041-.249-.024c-.106.024-1.793 1.14-5.061 3.345-.48.33-.913.49-1.302.48-.428-.008-1.252-.241-1.865-.44-.752-.245-1.349-.374-1.297-.789.027-.216.325-.437.893-.663 3.498-1.524 5.83-2.529 6.998-3.014 3.332-1.386 4.025-1.627 4.476-1.635z"
                />
              </svg>
            </a>
            <a
              v-if="!loading && content.settings.social.instagram"
              :href="!loading ? content.settings.social.instagram : ''"
            >
              <svg
                width="20"
                height="20"
                fill="#000000"
                role="img"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Instagram</title>
                <path
                  d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z"
                />
              </svg>
            </a>
          </div>
        </div>
      
        <div class="d-flex flex-column justify-content-start align-items-start">
          <section>
            <div>
              <h5 class="weight-bold fontsize-medium d-inline-block">
                ساعات پاسخگویی 10 الی 21
              </h5>
            </div>
          </section>
          <section class="mt-3">
            <h6 class="weight-bold fontsize-medium d-inline-block pr-1">
              شماره تماس پشتیبانی :
            </h6>
            <div>
              <template v-if="!loading">
                <a
                  :href="`tel:${content.settings.site.support_number}`"
                  class="fontsize-medium font-weight-bolder text-color-666 d-md-inline-block d-sm-block"
                  >{{ content.settings.site.support_number }}</a
                >
              </template>
              <template v-else>
                <span
                  ><b-skeleton width="105px" class="d-inline-block mr-2"
                /></span>
              </template>
            </div>
          </section>
       
     
         
        </div>
        <div class="d-flex justify-content-between align-items-start">
          <div>
            <section
              class="quick-access justify-content-between  align-items-baseline"
            >
              <h6 class="text-color-444 fontsize-medium font-weight-bold">
                دسترسی سریع:
              </h6>
              <nav>
                <ul
                  class="nav fontsize13 d-flex flex-column align-items-start"
                  style="gap: 10px;"
                >
                  <template v-if="!loading">
                    <li
                      v-for="item in content.menu.footer"
                      :key="item.id"
                      class="nav-item fontsize-small mr-1"
                    >
                      <DynamicLinker :item="item">{{
                        item.title
                      }}</DynamicLinker>
                    </li>
                  </template>
                  <template v-else>
                    <li v-for="index in 5" :key="index" class="nav-item">
                      <a class="nav-link">
                        <b-skeleton width="40px" />
                      </a>
                    </li>
                  </template>
                </ul>
              </nav>
            </section>
          </div>
          <a
            referrerpolicy="origin"
            target="_blank"
            href="https://trustseal.enamad.ir/?id=333878&amp;Code=rPWS2Gf47zh4FD6LE0u7"
            ><img
              referrerpolicy="origin"
              src="https://Trustseal.eNamad.ir/logo.aspx?id=333878&amp;Code=rPWS2Gf47zh4FD6LE0u7"
              alt=""
              style="cursor:pointer"
              id="rPWS2Gf47zh4FD6LE0u7"
          /></a>
        </div>
      </div>
      <hr />
      <div
        class="d-flex flex-wrap align-items-center justify-content-between mb-5 mb-md-0"
      >
        <p class="m-0">
          <svg
            class="d-sm-inline-block d-none ml-1"
            height="20"
            viewBox="0 0 512 512"
            width="20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="#666"
              d="m178.925781 177.925781c-42.5 42.5-42.5 111.648438 0 154.148438s111.648438 42.5 154.148438 0c7.8125-7.8125 20.476562-7.8125 28.285156 0s7.808594 20.476562 0 28.285156c-29.046875 29.046875-67.203125 43.570313-105.359375 43.570313s-76.3125-14.523438-105.359375-43.570313c-58.09375-58.097656-58.09375-152.625 0-210.71875 58.097656-58.09375 152.625-58.09375 210.71875 0 7.8125 7.808594 7.8125 20.472656 0 28.285156-7.808594 7.808594-20.472656 7.808594-28.285156 0-42.5-42.5-111.648438-42.5-154.148438 0zm258.09375-102.945312c-48.351562-48.351563-112.640625-74.980469-181.019531-74.980469s-132.667969 26.628906-181.019531 74.980469c-48.351563 48.351562-74.980469 112.640625-74.980469 181.019531s26.628906 132.667969 74.980469 181.019531c48.351562 48.351563 112.640625 74.980469 181.019531 74.980469 46.8125 0 92.617188-12.757812 132.460938-36.894531 9.449218-5.722657 12.46875-18.019531 6.746093-27.46875-5.726562-9.449219-18.023437-12.46875-27.46875-6.742188-33.59375 20.347657-72.234375 31.105469-111.738281 31.105469-119.101562 0-216-96.898438-216-216s96.898438-216 216-216 216 96.898438 216 216c0 42.589844-12.664062 84.042969-36.625 119.882812-6.140625 9.183594-3.671875 21.605469 5.507812 27.742188 9.1875 6.140625 21.605469 3.671875 27.742188-5.507812 28.378906-42.441407 43.375-91.585938 43.375-142.117188 0-68.378906-26.628906-132.667969-74.980469-181.019531zm0 0"
            />
          </svg>
          تمامی حقوق این سایت مربوط به
          <span class="font-weight-bold">سایت آنجل</span> می باشد
        </p>
        <p class="m-0">
          طراحی شده:
          <a
            class="m-0"
            style="color: var(--newTheme-color)"
            target="_blank"
            href="https://shetabit.com/"
            >گروه شتاب</a
          >
        </p>
      </div>
    </section>
  </footer>
</template>

<script>
import findUrl from "@/libraries/links/LinkResolver";
import DynamicLinker from "./DynamicLinker";
import { BSkeleton } from "bootstrap-vue";
import ZWhatsappSvg from "./ZWhatsappSvg";
import SocialNetworks from "./SocialNetworks";

export default {
  name: "Footer",
  components: { SocialNetworks, ZWhatsappSvg, DynamicLinker, BSkeleton },
  computed: {
    // دریافت محتوای درخواست هوم
    content() {
      return this.$store.getters["front/getHomeData"];
    },
    loading() {
      return this.$store.getters["front/getHomeDataStatus"];
    },
  },
  data() {
    return {
      email: null,
      disabled: false,
    };
  },
  methods: {
    findUrl,
    sendEmail() {
      if (!this.email) {
        return window.swal({
          title: "خطا!",
          text: "لطفا ایمیل خود را وارد کنید",
          icon: "warning",
          timer: 2000,
        });
      } else if (!this.validEmail(this.email)) {
        return window.swal({
          title: "خطا!",
          text: "لطفا فرمت ایمیل خود را به درستی وارد کنید",
          icon: "warning",
          timer: 2000,
        });
      }
      const fromData = new FormData();
      fromData.append("email", this.email);
      this.disabled = true;
      this.$axios.post("/front/newsletters", fromData).then((response) => {
        this.disabled = false;
        this.email = null;
        window.swal({
          title: "تبریک",
          text: "ایمیل شما برای عضویت در خبرنامه ما ثبت سیستم شد",
          icon: "success",
          timer: 2000,
        });
      });
    },
    validEmail: function(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },
};
</script>

<style scoped>
.footer-grid {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(2, 1fr);
}
.imgSkeleton {
  height: 70px;
}

.errorEmail {
  border-bottom: 1px solid red;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.enamad-container {
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
}

.newsletter-btn {
  background: var(--newTheme-color);
  color: white;
}

.get-subscribe form {
  height: 40px;
  border: 2px solid var(--color-da);
  transition: 0.3s;
}

.get-subscribe form:focus-within {
  border: 2px solid var(--color-theme2);
}

/* .get-subscribe form input::placeholder{
    color: white;
} */

.get-subscribe form svg > path {
  transition: 0.3s;
}

.get-subscribe form button {
  height: 38px;
  transition: 0.3s;
  position: relative;
  bottom: -1px;
  left: -3px;
}

.get-subscribe form button:hover {
  box-shadow: 0 0 5px #ccc;
}

.newsletter-title {
  flex-shrink: 0;
  font-size: 19px;
  font-weight: bold;
  margin-left: 10px;
}
@media screen and (max-width: 768px) {
  .footer-grid {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(1, 1fr);
  }
}
@media screen and (max-width: 587px) {
  .newsletter-title {
    font-size: 1rem;
  }
}

p {
  font-size: 14px;
}
</style>
