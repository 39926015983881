<template>
  <section
    class="product-details-img d-flex flex-column border-lg-left justify-content-center mb-lg-0 mb-3 mb-sm-4"
    :style="{ height: realMinHeight + 'px' }"
  >
    <div
      @wheel="isWheeling"
      :style="{
        minHeight: minHeight + 'px',
        'background-image':
          galleries == null ? 'radial-gradient(#f6f6f6,#f9f9f9, #f6f6f6)' : '',
      }"
      class="product-details-l-img "
    >
      <!-- Swiper -->
      <div
        v-if="galleries != null"
        @mousemove="mousemove"
        @mouseover="mouseover"
        @mouseleave="mouseleave"
        class="swiper-container gallery-swiper h-100 mySwiper"
      >
        <div class="swiper-wrapper">
          <div v-for="image in galleries" :key="image.id" class="swiper-slide">
            <Spinner2
              v-if="!isAlreadyLoaded(getImage(image))"
              class="loading"
            />
            <!-- MOBILE -->
          
            <img
              @load="imageLoaded(getImage(image))"
              v-if="mediaQueries.large"
              :src="getImage(image)"
              @click="openViewer(image)"
              :alt="imageAlt"
              loading="lazy"
              class="main"
              :style="'cursor: zoom-in'"
            />
            <!-- DESKTOP -->
            <PicZoom
              :alt="imageAlt"
              @load="imageLoaded"
              @clicked="openViewer(image)"
              v-if="!mediaQueries.large && galleries != null"
              box=".gallery-swiper"
              ref="piczoom"
              class="main"
              :url="getImage(image)"
            />
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
      <div
        v-else
        class="h-100"
        style="min-height: 400px;background: radial-gradient(#fafafa, #f4f4f4, #e1e1e1)"
      >
        <div class="swiper-slide p-2"></div>
      </div>
    </div>
    <div
      :style="{ minHeight: smallImageMinHeight + 'px' }"
      @scroll="isScrolling"
      v-if="!mediaQueries.mobileSize"
      class="product-details-s-img ml-2"
    >
      <div
        style="overflow-x: auto;overflow-y:hidden"
        v-if="galleries != null"
        class="d-flex flex-row"
        v-dragscroll="!mediaQueries.mobileSize"
      >
        <a
          class="small-pic"
          v-for="(image, index) in galleries"
          :key="image.id"
          :active="currentIndex === index"
          @click="goToSlide(index)"
        >
          <img
            @load="smallImageLoaded"
            :alt="imageAlt"
            loading="lazy"
            :src="getImage(image, 'lg')"
        /></a>
      </div>
      <div v-else class="d-flex flex-row">
        <a v-for="i in totalSkeletons"></a>
      </div>
    </div>
    <SilentBox ref="silentBox" :gallery="galleryFriendlyImages" />
  </section>
</template>
<script>
import Swiper from "swiper/swiper-bundle";
import { hasClass } from "@/libraries/VueMethods";
import "viewerjs/dist/viewer.css";
import { component as Viewer } from "v-viewer";
import SilentBox from "@/components/gallery/components/Gallery";
import PicZoom from "@/components/piczoom/PicZoom";
import { getImage } from "@/libraries/functions";
import Spinner2 from "@/parts/Front/components/shared/Spinner2";

export default {
  props: ["galleries", "selectedVariety", "imageAlt"],
  inject: ["mediaQueries"],
  data() {
    return {
      swiper: null,
      // If is scrolling dont change image
      scrolling: false,
      loadedImages: [],
      minHeight: "",
      smallImageMinHeight: "",
      realMinHeight: "",
    };
  },
  components: { Spinner2, Viewer, SilentBox, PicZoom },
  computed: {
    totalSkeletons() {
      return window.innerWidth < 450 ? 2 : 3;
    },
    currentIndex() {
      if (!this.swiper) {
        return -1;
      }
      return this.swiper.realIndex;
    },
    galleryFriendlyImages() {
      return this.galleries
        ? this.galleries.map((g) => ({ src: g.url, id: g.id }))
        : [];
    },
  },
  watch: {
    galleries(newVal) {
      if (newVal == null) {
        return;
      }
      ////swiper slider of product details
      if (this.swiper) this.swiper.destroy();
      this.initSwiper("productDetails", true, () => {
        this.swiper = new Swiper(".product-details-l-img .swiper-container", {
          spaceBetween: 1,
          //   centeredSlides: true,
          loop: false,
          speed: 500,

          pagination: {
            el: ".product-details-l-img .swiper-pagination",
            clickable: true,
          },
        });
      });
    },
    selectedVariety(selectedVariety) {
      // بریم به اولین عکس اون تنوع
      if (selectedVariety) {
        let index = this.galleries.findIndex((image) => {
          return image.variety_id == selectedVariety.id;
        });
        if (index > -1) {
          this.$nextTick(() => {
            this.$nextTick(() => {
              setTimeout(() => {
                this.goToSlide(index);
              }, 30);
            });
          });
        }
      }
    },
  },
  mounted() {
    if (window.globalMinHeight) {
      this.minHeight = window.globalMinHeight;
    }
    this.onResize();
    window.removeEventListener("resize", this.onResize);
    window.addEventListener("resize", this.onResize);
  },
  methods: {
    getImage,
    smallImageLoaded() {
      this.$nextTick(() => {
        let imgEl = document.querySelector(".product-details-s-img .small-pic");
        if (imgEl) {
          this.smallImageMinHeight = Math.max(
            imgEl.getBoundingClientRect().height,
            10
          );
        }
        this.checkIfBothLoaded();
      });
    },
    imageLoaded(src) {
      if (!this.loadedImages.includes(src)) {
        this.loadedImages.push(src);
      }
      this.$nextTick(() => {
        let imgEl = document.querySelector(
          ".product-details-l-img .swiper-slide"
        );
        if (imgEl) {
          this.minHeight = Math.max(imgEl.getBoundingClientRect().height, 10);
          window.globalMinHeight = this.minHeight;
        }
        this.checkIfBothLoaded();
      });
    },
    checkIfBothLoaded() {
      if (this.smallImageMinHeight && this.minHeight) {
        this.realMinHeight = document
          .querySelector(".product-details-img")
          .getBoundingClientRect().height;
      }
    },
    isAlreadyLoaded(src) {
      return this.loadedImages.includes(src);
    },
    mousemove(e) {
      this.$refs.piczoom &&
        this.$refs.piczoom[this.currentIndex] &&
        this.$refs.piczoom[this.currentIndex].mousemove(e);
    },
    mouseover(e) {
      this.$refs.piczoom &&
        this.$refs.piczoom[this.currentIndex] &&
        this.$refs.piczoom[this.currentIndex].mouseover(e);
    },
    mouseleave(e) {
      this.$refs.piczoom &&
        this.$refs.piczoom[this.currentIndex] &&
        this.$refs.piczoom[this.currentIndex].mouseleave(e);
    },
    onResize() {
      const bodyWidth = document.body.offsetWidth;
      const productLargeImg = document.querySelectorAll(
        ".product-details-l-img"
      )[0];
      const productSmallImg = document.querySelectorAll(
        ".product-details-s-img"
      )[0];
      const productSmallImgItem = document.querySelectorAll(
        ".product-details-s-img a"
      );
      if (productLargeImg && productSmallImg) {
        if (bodyWidth < 576) {
          for (const productSmlImg of productSmallImgItem) {
            productSmlImg.style.height = productSmlImg.offsetWidth + "px";
          }
        }
        if (bodyWidth < 400) {
          // productLargeImg.style.height = (productLargeImg.offsetWidth) / 0.75 + "px";
          // productSmallImg.style.height = (productLargeImg.offsetWidth) / 0.75 + "px";
          for (const productSmlImg of productSmallImgItem) {
            // productSmlImg.style.height = (productSmlImg.offsetWidth) + "px";
          }
        }
      }
    },
    goToSlide(slideNumber) {
      if (
        (this.scrolling && this.scrolling + 150 > new Date().getTime()) ||
        !this.swiper
      ) {
        return;
      }
      document
        .querySelectorAll(".mouse-cover-canvas")
        .forEach((el) => (el.style.display = "none"));

      this.swiper.slideTo(slideNumber);
    },
    // برای رفع باگ کلیک شدن موقع اسکرول
    isScrolling() {
      this.scrolling = new Date().getTime();
    },
    // Wheeling with mouse event
    isWheeling(e) {
      let deltaY = e.deltaY;
      let preventDefault = true;
      if (deltaY < -40 && this.currentIndex > 0) {
        this.goToSlide(this.currentIndex - 1);
      } else if (deltaY > 40 && this.currentIndex < this.galleries.length - 1) {
        this.goToSlide(this.currentIndex + 1);
      } else {
        preventDefault = false;
      }
      if (preventDefault) {
        e.preventDefault();
      } else {
        window.scroll({
          top: window.scrollY + (deltaY > 40 ? 200 : -200),
          left: window.scrollX,
          behavior: "smooth",
        });
      }
    },
    openViewer(image) {
      let index = this.galleryFriendlyImages.findIndex(
        (item) => item.src == image.url
      );
      this.$refs.silentBox.openOverlay(
        this.galleryFriendlyImages[index],
        index
      );
      // let galleries = [...this.galleries]
      // galleries.sort((a,b) => {
      //   if (a.id == image.id) return -1;
      //   if (b.id != image.id) return 1;
      //   return 0;
      // });
      // this.$viewerApi({
      //   images: galleries.map(image => image.url),
      //   options: {
      //     zoomable:false,
      //     rotatable: false,
      //     navbar: true,
      //     scalable: false,
      //     title: false
      //   }
      // })
    },
  },
};
</script>
<style scoped>
.product-details-l-img img.main {
  max-width: 100%;
  max-height: 100%;
  /* width: 100%;
  height: 100%; */
  position: relative;
  /*top: 50%;*/
  top: 0;
  /*transform: translateY(-50%);*/
}

.small-pic {
  transform: scale(1);
  transition: 200ms all;
}
.small-pic[active] {
  /*border: 1px solid #8d8d8d;*/
  border: 0px solid #000000;
  box-shadow: 0 0 0px 2px #000000;
  transform: scale(1.03);
}

.enlarge {
  cursor: pointer;
  position: absolute;
  width: 27px;
  height: 27px;
  left: 10px;
  top: 10px;
  padding: 6px;
  background: white;
  border-radius: 50%;
  transition: 200ms all;
  user-select: none;
}
.enlarge:hover {
  width: 29px;
  height: 29px;
  left: 9px;
  top: 9px;
}

.enlarge img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: baseline;
  user-select: none;
}

.product-details-s-img a {
  width: 85px;
  /*height: 124px;*/
  /*padding: 5px;*/
  background-image: radial-gradient(#fafafa, #f4f4f4, #e1e1e1);
  margin: 5px 10px;
  /*overflow: hidden;*/
  text-align: center;
  flex-shrink: 0;
}

.product-details-s-img a img {
  max-height: 100%;
  max-width: 100%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@media screen and (max-width: 575px) {
  .product-details-s-img a {
    margin: 5px 5px;
  }
  .product-details-s-img {
    padding-top: 1px;
  }
  .product-details-l-img {
    height: auto;
  }
}
</style>
<style>
.zoom-on-hover {
  height: 100%;
}

.zoom-on-hover .normal {
  height: 100%;
}

.product-details-img img.zoom {
  cursor: zoom-out;
}

.viewer-canvas > img {
  left: 50%;
  transform: translateX(-50%) !important;
  margin-left: unset !important;
}

.viewer-list {
  width: 100% !important;
}

.viewer-list > li {
  min-width: 40px !important;
}

.viewer-reset {
  display: none !important;
}

.loading {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
