<template>
  <b-modal
    :ok-disabled="disabled"
    :cancel-disabled="disabled"
    @ok="submit"
    class="in-form-modal"
    ok-title="ثبت"
    cancel-title="لغو"
    id="slider-modal"
    :title="mode == 'edit' ? 'ویرایش اسلایدر' : 'اسلایدر جدید'"
  >
    <form ref="form" @submit.prevent="submit">
      <b-form-group>
        <label for="title"> عنوان <i class="text-danger">*</i></label>
        <b-form-input
          id="title"
          name="title"
          v-model="slider.title"
          :disabled="disabled"
        ></b-form-input>
      </b-form-group>

      <b-form-group>
        <label for="link"> لینک <i class="text-danger">*</i></label>
        <multiselect
          v-model="slider.linkable_type"
          @input="loadModels(slider.linkable_type)"
          placeholder="انتخاب کنید"
          :allow-empty="false"
          open-direction="bottom"
          :options="linkables"
          label="label"
          track-by="unique_type"
          :searchable="true"
          :close-on-select="true"
          :show-labels="false"
          :disabled="disabled"
        >
          <template slot="noOptions"> موردی یافت نشد </template>
          <template slot="noResult"> موردی یافت نشد </template>
        </multiselect>
        <input
          v-if="slider.linkable_type != null"
          type="hidden"
          name="linkable_type"
          :value="
            slider.linkable_type.linkable_type != 'link_url'
              ? slider.linkable_type.linkable_type
              : ''
          "
        />
      </b-form-group>
      <b-form-group
        v-if="
          slider.linkable_type != null &&
          slider.linkable_type.models != null && slider.linkable_type.models != 'link_url'
        "
      >
        <label for="item"> آیتم <i class="text-danger">*</i></label>
        <multiselect
          v-model="slider.linkable_id"
          placeholder="انتخاب کنید"
          :allow-empty="false"
          open-direction="bottom"
          :options="slider.linkable_type.models"
          :label="modelLabel == '' ? labelMultiselect : modelLabel"
          track-by="id"
          :searchable="true"
          :close-on-select="true"
          :show-labels="false"
          :disabled="disabled"
        >
          <template slot="noOptions"> موردی یافت نشد </template>
          <template slot="noResult"> موردی یافت نشد </template>
        </multiselect>
        <input
          type="hidden"
          name="linkable_id"
          :value="slider.linkable_id != null ? slider.linkable_id.id : ''"
        />
      </b-form-group>
      <b-form-group
        v-if="
          slider.linkable_type != null &&
          slider.linkable_type.linkable_type == 'link_url'
        "
      >
        <label for=""> لینک <i class="text-danger">*</i></label>
        <b-form-input
          v-model="slider.link"
          name="link"
          id="link"
          :disabled="disabled"
        ></b-form-input>
      </b-form-group>
      <template v-if="customFields">
        <Input v-for="(customField,index) in customFields" :key="index"
               v-model="customFieldsValues[index]"
               :name="customField.name" :type="customField.type" />
      </template>
      <b-form-group>
        <label> توضیحات</label>
        <b-form-textarea name="description" v-model="slider.description" rows="4"></b-form-textarea>
      </b-form-group>
      <b-form-group>
        <label for="image"> تصویر <i class="text-danger">*</i></label>
        <b-form-file
          placeholder="فایلی انتخاب نشده"
          name="image"
          :disabled="disabled"
        ></b-form-file>
        <ImageHolder class="mt-2" v-if="slider.image && slider.image.url">
          <img :src="slider.image.url" />
        </ImageHolder>
      </b-form-group>
      <b-form-checkbox
        class="mb-3"
        id="status"
        :disabled="disabled"
        v-model="slider.status"
        value="1"
        unchecked-value="0"
      >
        <span class="pr-5">وضعیت</span>
      </b-form-checkbox>
      <input type="submit" style="visibility: hidden; position: absolute" />
    </form>
  </b-modal>
</template>
<script>
import SliderFormImpl from '@@/core/components/slider/SliderForm/SliderFormImpl'
export default {
  extends: SliderFormImpl
}
</script>
